import React from 'react';

import {
  FacebookShareButton,
  FacebookIcon,
  LinkedinShareButton,
  LinkedinIcon,
  TwitterShareButton,
  TwitterIcon,
  WhatsappShareButton,
  WhatsappIcon,
  RedditShareButton,
  RedditIcon,
} from 'react-share';

const ShareButtons = ({
  title, url, twitterHandle, tags,
}) => (
  <div>
    <FacebookShareButton url={url}>
      <FacebookIcon size={40} round />
    </FacebookShareButton>

    <TwitterShareButton
      url={url}
      title={title}
      via={twitterHandle}
      hashtags={tags}
    >
      <TwitterIcon size={40} round />
    </TwitterShareButton>

    <LinkedinShareButton url={url}>
      <LinkedinIcon size={40} round />
    </LinkedinShareButton>

    <RedditShareButton url={url} title={title}>
      <RedditIcon size={40} round />
    </RedditShareButton>

    <WhatsappShareButton url={url} title={title}>
      <WhatsappIcon size={40} round />
    </WhatsappShareButton>
  </div>
);
export default ShareButtons;
